var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.handleEnter.apply(null, arguments)
        },
      },
    },
    [
      _vm.withCamera
        ? _c(
            "div",
            { staticClass: "sync-video" },
            [
              _c("span", [_vm._v("同步回放车载视频")]),
              _c("a-switch", {
                on: { change: _vm.handleSetSyncVideo },
                model: {
                  value: _vm.isSyncVideo,
                  callback: function ($$v) {
                    _vm.isSyncVideo = $$v
                  },
                  expression: "isSyncVideo",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "sync-video" },
        [
          _c("span", [_vm._v("显示卫星信号强弱")]),
          _c("a-switch", {
            on: { change: _vm.handleShowSignal },
            model: {
              value: _vm.isShowSignal,
              callback: function ($$v) {
                _vm.isShowSignal = $$v
              },
              expression: "isShowSignal",
            },
          }),
        ],
        1
      ),
      _vm.historyTrackPointsArray.length > 0
        ? _c(
            "div",
            { staticClass: "history-track" },
            [
              _c("speed", {
                attrs: { isSyncVideo: _vm.isSyncVideo },
                on: { onSpeedChange: _vm.handleSpeedChange },
              }),
              _c(
                "div",
                { staticClass: "track-play" },
                [
                  _c("a-icon", {
                    staticClass: "track-play-icon",
                    attrs: { type: _vm.status === 1 ? "pause" : "play-circle" },
                    on: { click: _vm.handlePlay },
                  }),
                  _c("a-slider", {
                    staticClass: "track-slider",
                    attrs: {
                      disabled: _vm.trackIniting,
                      min: 0,
                      max: _vm.historyTrackPointsArray.length,
                      "default-value": 0,
                      tipFormatter: _vm.getWorkTime,
                    },
                    on: {
                      afterChange: _vm.handleSliderAfterChange,
                      change: _vm.handleSliderChange,
                    },
                    model: {
                      value: _vm.position,
                      callback: function ($$v) {
                        _vm.position = $$v
                      },
                      expression: "position",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "search-container" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "op-time" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: _vm.getBtnType(1) },
                  on: { click: _vm.handleToday },
                },
                [_vm._v("当日")]
              ),
              _c(
                "a-button",
                {
                  attrs: { type: _vm.getBtnType(2) },
                  on: { click: _vm.handleYesterday },
                },
                [_vm._v("昨日")]
              ),
              _c(
                "a-button",
                {
                  attrs: { type: _vm.getBtnType(3) },
                  on: { click: _vm.handleLast24Hour },
                },
                [_vm._v("过去24小时")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "search-item" }, [
            _c("div", [_vm._v("开始时间")]),
            _c(
              "div",
              [
                _c("a-date-picker", {
                  ref: "startRef",
                  attrs: {
                    "show-time": "",
                    placeholder: "选择时间",
                    "value-format": "YYYY-MM-DD HH:mm:ss",
                    format: "YYYY-MM-DD HH:mm:ss",
                    "default-value": this.startTime,
                    allowClear: false,
                  },
                  on: { change: _vm.handleStartTimeChange },
                  model: {
                    value: _vm.startTime,
                    callback: function ($$v) {
                      _vm.startTime = $$v
                    },
                    expression: "startTime",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "search-item" }, [
            _c("div", [_vm._v("结束时间")]),
            _c(
              "div",
              [
                _c("a-date-picker", {
                  ref: "startRef",
                  attrs: {
                    "show-time": "",
                    placeholder: "选择时间",
                    "value-format": "YYYY-MM-DD HH:mm:ss",
                    format: "YYYY-MM-DD HH:mm:ss",
                    "default-value": this.endTime,
                    allowClear: false,
                  },
                  on: { change: _vm.handleEndTimeChange },
                  model: {
                    value: _vm.endTime,
                    callback: function ($$v) {
                      _vm.endTime = $$v
                    },
                    expression: "endTime",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "history-search" },
          [
            _c(
              "a-button",
              {
                attrs: {
                  disabled: _vm.isGettingVehicleInfo,
                  type: "primary",
                  loading: _vm.trackIniting,
                  icon: "search",
                },
                on: { click: _vm.handleSearch },
              },
              [_vm._v(" 查询 ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "track-info" },
        [
          _vm.params.trackRemark
            ? _c("div", { staticClass: "track-remark" }, [
                _vm._v(_vm._s(_vm.params.trackRemark)),
              ])
            : _vm._e(),
          _vm.trackInfo
            ? _c(_vm.currentTrackInfoWindowComponent.component, {
                tag: "component",
                attrs: {
                  taskStatusOptions: _vm.taskStatusOptions,
                  "object-info": _vm.params.currentTrackObject,
                  "track-info": _vm.trackInfo,
                  "show-footer": false,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm.isSyncVideo
            ? _c("play", {
                ref: "trackVedioPlay",
                attrs: {
                  queryCaremas: _vm.playCaremas,
                  "object-info": _vm.objectInfo,
                  camerasObj: _vm.camerasObj,
                  currentSpeed: _vm.currentSpeed,
                },
                on: {
                  vedioReadyToPlay: _vm.vedioReadyToPlay,
                  videoUnfreezeStart: _vm.videoUnfreezeStart,
                  videoUnfreezeEnd: _vm.videoUnfreezeEnd,
                  loadHistoryList: _vm.loadHistoryList,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
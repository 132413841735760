<template>
  <!-- 历史轨迹UI -->
  <div @keyup.enter="handleEnter">
    <div class="sync-video" v-if="withCamera">
      <span>同步回放车载视频</span><a-switch v-model="isSyncVideo" @change="handleSetSyncVideo" />
    </div>
    <div class="sync-video">
      <span>显示卫星信号强弱</span><a-switch v-model="isShowSignal" @change="handleShowSignal" />
    </div>
    <div class="history-track" v-if="historyTrackPointsArray.length > 0">
      <speed @onSpeedChange="handleSpeedChange" :isSyncVideo="isSyncVideo" />
      <div class="track-play">
        <a-icon :type="status === 1 ? 'pause' : 'play-circle'" class="track-play-icon" @click="handlePlay" />
        <a-slider
          :disabled="trackIniting"
          :min="0"
          :max="historyTrackPointsArray.length"
          :default-value="0"
          class="track-slider"
          @afterChange="handleSliderAfterChange"
          @change="handleSliderChange"
          :tipFormatter="getWorkTime"
          v-model="position"
        />
      </div>
    </div>
    <div class="search-container">
      <div>
        <div class="op-time">
          <a-button :type="getBtnType(1)" @click="handleToday">当日</a-button>
          <a-button :type="getBtnType(2)" @click="handleYesterday">昨日</a-button>
          <a-button :type="getBtnType(3)" @click="handleLast24Hour">过去24小时</a-button>
        </div>
        <div class="search-item">
          <div>开始时间</div>
          <div>
            <a-date-picker
              show-time
              placeholder="选择时间"
              ref="startRef"
              value-format="YYYY-MM-DD HH:mm:ss"
              format="YYYY-MM-DD HH:mm:ss"
              @change="handleStartTimeChange"
              v-model="startTime"
              :default-value="this.startTime"
              :allowClear="false"
            />
          </div>
        </div>
        <div class="search-item">
          <div>结束时间</div>
          <div>
            <a-date-picker
              show-time
              placeholder="选择时间"
              ref="startRef"
              value-format="YYYY-MM-DD HH:mm:ss"
              format="YYYY-MM-DD HH:mm:ss"
              @change="handleEndTimeChange"
              v-model="endTime"
              :default-value="this.endTime"
              :allowClear="false"
            />
          </div>
        </div>
      </div>
      <div class="history-search">
        <a-button
          :disabled="isGettingVehicleInfo"
          type="primary"
          :loading="trackIniting"
          icon="search"
          @click="handleSearch"
        >
          查询
        </a-button>
      </div>
    </div>
    <div class="track-info">
      <div v-if="params.trackRemark" class="track-remark">{{ params.trackRemark }}</div>
      <component
        :is="currentTrackInfoWindowComponent.component"
        :taskStatusOptions="taskStatusOptions"
        :object-info="params.currentTrackObject"
        :track-info="trackInfo"
        :show-footer="false"
        v-if="trackInfo"
      />
    </div>
    <div>
      <play
        v-if="isSyncVideo"
        ref="trackVedioPlay"
        :queryCaremas="playCaremas"
        @vedioReadyToPlay="vedioReadyToPlay"
        @videoUnfreezeStart="videoUnfreezeStart"
        @videoUnfreezeEnd="videoUnfreezeEnd"
        @loadHistoryList="loadHistoryList"
        :object-info="objectInfo"
        :camerasObj="camerasObj"
        :currentSpeed="currentSpeed"
      ></play>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { parseDateTime } from '@/utils/ruoyi'
import Speed from './speed.vue'
import { GetTrackInfoWindowConfig } from '../config/infoWindowConfig'
import { Slider } from 'ant-design-vue'
import moment from 'moment'
import play from './play'
import { videoHistoryQuery } from '@/api/iot/vehicleAbilityCamera'
import { getVideoHistory } from '@/api/iot/vehicle'

export default {
  name: 'MapHistoryTrack',
  components: { Speed, ASlider: Slider, play },
  props: {
    isGettingVehicleInfo: {
      type: Boolean,
      default: false
    },
    historyTrackPointsArray: {
      type: Array,
      default: () => {}
    },
    params: {
      type: Object,
      default: () => {}
    },
    monitorRef: {
      type: Object,
      default: () => {}
    },
    taskStatusOptions: {
      type: Array,
      default: () => {}
    },
    objectInfo: {
      type: Object,
      default: () => {}
    },
    initTrackDate: {
      type: String,
      default: ''
    }
  },
  data() {
    // 节流控制
    this.handleSliderAfterChange = debounce(this.handleSliderAfterChange, 800)
    return {
      isShowSignal: false,
      startTime: parseDateTime(new Date(), 'yyyy-MM-dd') + ' 00:00:00',
      endTime: parseDateTime(new Date(), 'yyyy-MM-dd') + ' 23:59:59',
      dateFormat: 'YYYY-MM-DD',
      workTime: '',
      position: 0,
      // 状态： 0 初始状态，1正在运动，2停止
      status: 0,
      routePlanLanes: [],
      currentTrackInfoWindowComponent: {},
      trackInfo: null,
      // 判断轨迹是否正在初始化
      trackIniting: false,
      curBtnStatus: 1,
      isSyncVideo: false,
      camerasObj: {},
      queryParam: {
        streamId: null,
        startTime: null,
        endTime: null,
        pageNum: 1,
        pageSize: 200,
        pageObj: {}
      },
      playCaremas: [],
      // 是否正在跳转中
      isJumping: false,
      withCamera: false,
      currentSpeed: 1,
      // 是否在拖动滑动条
      isDragSlider: false
    }
  },
  created() {
    this.setTrackInfoWindow()
  },
  watch: {
    historyTrackPointsArray(newVal, oldVal) {
      if (newVal.length > 0) {
        this.trackInfo = newVal[0]
        // 初始化归位
        this.position = 0
        this.handleTrackChange()
      } else {
        this.trackInfo = null
        // 如果查询不到数据，不存在轨迹播放初始化
        this.trackIniting = false
      }
    },
    'params.currentTrackObject'(newVal, oldVal) {
      console.log('currentTrackObject', newVal)
      if (newVal && this.lastVehicleId !== newVal.vehicleId) {
        // 当切换车辆时重置同步播放按钮
        this.lastVehicleId = newVal.vehicleId
        this.isSyncVideo = false
      }
    },
    position(newVal, oldVal) {
      // 在拖动滑动条过程中不触发轨迹点位变化，只有在滑动结束后触发跳转
      if (this.isDragSlider) {
        return
      }
      if (newVal === 0) {
        return
      }
      this.handleTrackChange()
    },
    objectInfo(newVal, oldVal) {
      this.isHaveCamera()
    }
  },
  mounted() {
    this.isHaveCamera()
    // 订阅移动一步
    this.token = window.PubSub.subscribe('step', (msg, i) => {
      // console.log(`step=${i},position=${this.position}`)
      if (this.isJumping) {
        return
      }
      // 轨迹在播放过程中才允许改变进度条值
      if (this.status === 1) {
        this.position = i
        this.trackInfo = this.historyTrackPointsArray[this.position]
      }
    })
    this.tokenFinish = window.PubSub.subscribe('end', (msg, flag) => {
      if (flag) {
        this.position = 0
        // 运动结束，状态为初始值
        this.status = 0
        this.$refs.trackVedioPlay && this.$refs.trackVedioPlay.handleTrackFinish()
        // this.handleMapTrackPlayStop()
      }
    })
    this.tokenSlide = window.PubSub.subscribe('slide', (msg, i) => {
      this.position = i
      this.trackInfo = this.historyTrackPointsArray[i]
    })
    // 路(书初始化后
    this.tokenAfterInitLuShu = window.PubSub.subscribe('afterTrackIniting', (msg, i) => {
      this.trackIniting = false
    })
    if (this.initTrackDate) {
      console.log('track')
      this.startTime = this.initTrackDate + ' 00:00:00'
      this.endTime = this.initTrackDate + ' 23:59:59'
      setTimeout(() => {
        this.autoTrackSearch()
      }, 500)
    }

    document.onkeydown = (e) => {
      const theEvent = window.event || e
      const code = theEvent.keyCode || theEvent.which || theEvent.charCode
      if (code === 13) {
        this.handleSearch()
      }
    }
  },
  beforeDestroy() {
    console.log('destroy')
    window.PubSub.unsubscribe(this.token)
    window.PubSub.unsubscribe(this.tokenFinish)
    window.PubSub.unsubscribe(this.tokenSlide)
    window.PubSub.unsubscribe(this.tokenAfterInitLuShu)
  },
  methods: {
    autoTrackSearch() {
       // 从智驾日数据统计跳转到轨迹播放同步视频直接播放时需要检查objectInfo是否为空，否则同步视频会报错
       if (!this.objectInfo) {
        setTimeout(() => {
           this.autoTrackSearch()
        }, 1000)
       } else {
         this.handleSearch()
       }
    },
    handleShowSignal() {
      this.monitorRef.mapSetShowSignal(this.isShowSignal)
    },
    // 视频播放开始解冻通知轨迹播放
    videoUnfreezeStart(position, time) {
      console.log(`视频播放开始解冻通知轨迹播放,position=${position}, time=${time}`)
      if (!this.isSyncVideo) {
        return
      }
      // 解冻时间
      this.freeTime = time
      const historyTrackPointsArray = this.historyTrackPointsArray
      const jumpVal = historyTrackPointsArray.findIndex((p) => p.time === time)
      console.log('轨迹播放重定向,position', jumpVal)
      console.log('轨迹播放重定向,status', this.status)
      if (jumpVal < 0) {
        return
      }
      this.inFreeze = true
      const oldStatus = this.status
      // 当前轨迹正在播放中
      if (this.status === 1) {
        this.status = 2
        this.handleMapTrackPlay(this.status, oldStatus)
        setTimeout(() => {
          this.handleMapTrackPlayJumpTo(jumpVal, this.status, true)
        }, 500)
      }
    },
    // 视频播放完成解冻通知轨迹播放
    videoUnfreezeEnd() {
      console.log(`视频播放完成解冻通知轨迹播放`)
      if (!this.isSyncVideo) {
        return
      }
      this.inFreeze = false
      // 当前轨迹播放暂停中
      if (this.status === 2) {
        this.status = 1
        this.handleMapTrackPlay(1, 2)
      }
    },
    // 视频通知重置轨迹位置
    videoNotifyResetPosition(position, time) {
      console.log(`视频通知重置轨迹位置,position=${position}, time=${time}`)
    },
    isHaveCamera() {
      const obj = this.objectInfo
      if (obj) {
        if (!obj.cameras || obj.cameras.length === 0) {
          if (obj.rtc && obj.rtc.vehicleCameraDeviceInfos.length > 0) {
            this.withCamera = true
          } else {
            this.withCamera = false
          }
        } else {
          this.withCamera = true
        }
      }
      if (!this.withCamera) {
        this.isSyncVideo = false
      }
    },
    handleSliderChange(val) {
      this.isJumping = true
      this.isDragSlider = true
    },
    handleTrackChange() {
      const n = this.historyTrackPointsArray.length
      if (n >= 0 && this.position >= 0 && this.position < n) {
        const time = this.historyTrackPointsArray[this.position].time
        // console.log(`当前轨迹点位时间=${time}，索引=${this.position}`)
        this.$emit('onTrackChange', this.position, time)
        this.$refs.trackVedioPlay && this.$refs.trackVedioPlay.handleTrackChange(time, this.position, this.status)
      }
    },
    getBtnType(status) {
      if (status === this.curBtnStatus) {
        return 'primary'
      } else {
        return 'default'
      }
    },
    handleToday() {
      this.curBtnStatus = 1
      this.startTime = this.parseDateTime(new Date(), 'yyyy-MM-dd') + ' 00:00:00'
      this.endTime = this.parseDateTime(new Date(), 'yyyy-MM-dd') + ' 23:59:59'
    },
    handleYesterday() {
      this.curBtnStatus = 2
      this.startTime = moment().subtract(1, 'days').format('YYYY-MM-DD') + ' 00:00:00'
      this.endTime = moment().subtract(1, 'days').format('YYYY-MM-DD') + ' 23:59:59'
    },
    handleLast24Hour() {
      this.curBtnStatus = 3
      this.startTime = moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')
      this.endTime = this.parseDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss')
    },
    setTrackInfoWindow() {
      const trackInfoWindowModules = GetTrackInfoWindowConfig()[0].modules
      if (!this.currentTrackInfoWindowComponent.component) {
        const tmpArr = trackInfoWindowModules.filter((p) => p.id === this.params.moduleKey)
        if (tmpArr.length === 1) {
          console.log('找到轨迹信息组件配置', this.params.moduleKey)
          this.currentTrackInfoWindowComponent = tmpArr[0]
        } else {
          console.log('找不轨迹信息组件配置', this.params.moduleKey)
          this.currentTrackInfoWindowComponent = {}
        }
      }
    },
    checkDate() {
      const t1 = new Date(this.startTime)
      const t2 = new Date(this.endTime)
      const diffDays = (t2.getTime() - t1.getTime()) / 1000 / 60 / 60 / 24
      if (diffDays > 2) {
        this.$warning({
          title: 'Warning',
          content: '查询时间要求在2天内！'
        })
        return false
      } else if (diffDays < 0) {
        this.$warning({
          title: 'Warning',
          content: '开始时间不能大于结束时间！'
        })
        return false
      }
      return true
    },
    handleSearch() {
      console.log('handleSearch')
      if (!this.checkDate()) {
        return
      }
      const params = {}
      params.startTime = this.startTime
      params.endTime = this.endTime
      this.params.searchTime = params
      // 运动结束，状态为初始值
      this.status = 0
      // 停止轨播放
      this.handleMapTrackPlayStop()
      this.$emit('onHistoryTrackSearch', params)
      this.handleLoad()
      if (this.monitorRef.$parent.currentSelectedId) {
        this.trackIniting = true
      }
    },

    handleLoad(playDate) {
      if (this.objectInfo.rtc) {
        this.getVideoHistory()
      } else {
        this.playCaremas = []
        let index = 0
        for (const item of this.objectInfo.cameras) {
          if (item.streamId) {
            this.loadHistoryList(item.streamId, null, item.cameraName, index === 0)
            index++
          }
        }
      }
      this.$refs.trackVedioPlay && this.$refs.trackVedioPlay.handleSearch()
    },
    getVideoHistory() {
      console.log('getVideoHistory *******')
      getVideoHistory({
        startTime: this.startTime.replace(' ', 'T') + '+08:00',
        endTime: this.endTime.replace(' ', 'T') + '+08:00',
        vehicleInnerId: this.objectInfo.innerId,
        pageNum: 1,
        pageSize: 30
      }).then((res) => {
        const playCaremas = res.data.cameras || []
        this.playCaremas = playCaremas
        let index = 0
        for (const item of playCaremas) {
          if (item.streamId) {
            this.loadHistoryList(item.streamId, null, item.cameraName, index === 0)
            index++
          }
        }
      })
    },
    loadHistoryList(streamId, arr, cameraName, canPlay) {
      // const streamId = e.target.value
      console.log('loadHistoryList ***', streamId)
      this.queryParam.startTime = this.startTime.replace(' ', 'T') + '+08:00'
      this.queryParam.endTime = this.endTime.replace(' ', 'T') + '+08:00'
      this.historyLoading = true
      if (streamId) {
        this.queryParam.vehicleInnerId = this.objectInfo.innerId
        videoHistoryQuery({
          ...this.queryParam,
          streamId
        })
          .then((response) => {
            console.log('video history list={}', response)
            if (!response.records) {
              this.historyLoading = false
              this.$set(this.camerasObj, streamId, { videoList: [], cameraName, canPlay })
              // this.historyList = []
              return
            }
            // this.historyList = response.records
            if (arr) {
              this.$set(this.camerasObj[streamId], 'videoList', arr)
              for (let i = 0; i < response.records.length; i++) {
                if (this.camerasObj[streamId].videoList[i]) {
                  this.$set(this.camerasObj[streamId].videoList[i], 'url', response.records[i].url)
                } else {
                  this.camerasObj[streamId].videoList.push(response.records[i])
                }
              }
            } else {
              this.$set(this.camerasObj, streamId, { videoList: response.records, cameraName, canPlay })
              for (const item of this.camerasObj[streamId].videoList) {
                const startTime = moment(item.startTime)
                const endTime = moment(item.endTime)
                const t = endTime - startTime
                const d = new Date(t)
                const h = Math.floor(t / (1000 * 60 * 60))
                const m = d.getMinutes()
                const s = d.getSeconds()
                this.$set(item, 'allTime', (h ? h + '时' : '') + m + '分' + s + '秒')
                this.$set(item, 'isFreeze', this.checkTimeBeforeWeek(endTime))
              }
            }
            // this.total = response.totalCount
            this.historyLoading = false
          })
          .catch(() => {
            this.historyLoading = false
          })
      }
    },
    checkTimeBeforeWeek(time) {
      const d = new Date()
      const nt = d.getTime()
      const wt = 7 * 24 * 60 * 60 * 1000
      if (nt - time >= wt) {
        return true
      }
      return false
    },

    handleStartTimeChange(val) {
      this.startTime = val
      const startDate = val.substr(0, 10)
      this.endTime = startDate + ' 23:59:59'
    },
    handleEndTimeChange(val) {
      this.endTime = val
    },
    handleSliderAfterChange(val) {
      this.isDragSlider = false
      console.log(`LuShu handleSliderAfterChange val=${val},time=${this.historyTrackPointsArray[val].time}`)
      if (this.status !== 2) {
        // 如果非停止状态，则为运动状态
        // this.status = 1
      }
      // 轨迹播放跳转, 注意数组索引问题
      if (val > 0) {
        val = val - 1
      }
      this.isJumping = false
      this.handleMapTrackPlayJumpTo(val, this.status)
    },
    handlePlay() {
      console.log('handlePlay')
      // 当轨迹初始化完成之后才可以播放
      if (this.trackIniting) {
        return
      }
      if (this.isSyncVideo && this.inFreeze) {
        console.log('同步视频同时正在解冻中操作按钮不能播放')
        return
      }
      const oldStatus = this.status
      if (this.status === 0 || this.status === 2) {
        // 当为初始状态与停止状态，点击即为运动中
        this.status = 1
      } else if (this.status === 1) {
        // 当状态为1，点击即为停止状态
        this.status = 2
      }
      if (this.isSyncVideo) {
        this.orderVedioPlay(this.status, oldStatus)
      }
      this.handleMapTrackPlay(this.status, oldStatus)
      if (this.position === this.historyTrackPointsArray.length) {
        this.position = 0
      }
    },
    getWorkTime() {
      let time = ''
      const n = this.historyTrackPointsArray.length
      if (n >= 0 && this.position >= 0 && this.position < n) {
        time = this.historyTrackPointsArray[this.position].time
      }
      if (time && time.length > 23) {
        return this.parseDateTime(new Date(time), 'yyyy-MM-dd HH:mm:ss')
      }
      return time
    },
    // 播放速度改变
    handleSpeedChange(val) {
      // this.$emit('onMapSetTrackPlaySpeed', val)
      console.log('改变播放速度', val)
      this.currentSpeed = val
      this.$refs.trackVedioPlay && this.$refs.trackVedioPlay.handleSpeedChange(val)
      this.handleMapSetTrackPlaySpeed(val)
    },

    orderVedioPlay(status, oldStatus) {
      const n = this.historyTrackPointsArray.length
      if (n >= 0 && this.position >= 0 && this.position < n) {
        const time = this.historyTrackPointsArray[this.position].time
        // console.log(`当前轨迹点位时间=${time}，索引=${this.position}`)
        this.$refs.trackVedioPlay && this.$refs.trackVedioPlay.trackPlay(time, status, oldStatus, this.inFreeze)
      }
    },

    vedioReadyToPlay(statusInfo, jumpVal) {
      console.log('vedioReadyToPlay', arguments)
      console.log('vedioReadyToPlay **** jumpVal', jumpVal)
    },

    // 轨迹播放
    handleMapTrackPlay(status, oldStatus) {
      // 需要调用地图组件的方法
      this.monitorRef.mapTrackPlay(status, oldStatus)
    },
    // 轨迹播放跳转
    handleMapTrackPlayJumpTo(val, status, inFreeze) {
      console.log('轨迹播放跳转:handleMapTrackPlayJumpTo', val)
      // 通知跳转到不同的时间点位
      if (this.isSyncVideo) {
        const n = this.historyTrackPointsArray.length
        if (n >= 0 && val >= 0 && val < n) {
          const time = this.historyTrackPointsArray[val].time
          // console.log(`轨迹跳转=${time}，索引=${this.position}`)
          this.$emit('onTrackJumpTo', val, time, status)
          this.$refs.trackVedioPlay && this.$refs.trackVedioPlay.trackJumpPlay(time, val, status, inFreeze)
        }
      }
      this.monitorRef.mapTrackPlayJumpTo(val, status)
    },
    // 轨迹播放暂停
    handleMapTrackPlayPause() {
      this.monitorRef.mapTrackPlayPause()
    },
    // 轨迹播放停止
    handleMapTrackPlayStop() {
      this.monitorRef.mapTrackPlayStop()
    },
    // 改变轨迹播放速度
    handleMapSetTrackPlaySpeed(val) {
      this.monitorRef.mapSetTrackPlaySpeed(val)
    },
    // 设置同步车端视频
    handleSetSyncVideo() {
      this.monitorRef.mapSetSyncVideo(this.isSyncVideo)
    },
    // 取消同步视频开关
    cancelSyncVideo() {
      this.isSyncVideo = false
    }
  }
}
</script>

<style lang="less">
.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-item {
    display: flex;
    margin: 10px 0;
    align-items: center;
    > div:first-child {
      min-width: 70px;
    }
  }
  .history-search {
    // margin-top: 15px;
    text-align: center !important;
  }
  .op-time {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    button {
      flex: 1;
    }
    button:nth-child(2) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
.track-info {
  margin-bottom: 20px;
  .track-remark {
    padding-left: 5px;
    font-size: 15px;
    font-weight: 800;
  }
}
.history-track {
  display: flex;
  .ant-select-selection {
    min-width: 70px;
  }
  .track-play {
    display: flex;
    flex: 1;
    margin-left: 5px;
    justify-items: center;
    border: 1px solid #ebeef5;
    border-radius: 5px;
    padding-right: 5px;
    .track-play-icon {
      flex: 0 0 50px;
      min-width: 50px;
      align-self: center;
      font-size: 26px;
    }
    .track-slider {
      flex: 1;
    }
  }
}
.sync-video {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  > span {
    margin-right: 10px;
  }
}
</style>
